import React from "react";
import { AppProvider } from "./src/components/AppContext";

import "@fontsource/barlow-condensed/400.css";
import "@fontsource/barlow-condensed/500.css";
import "@fontsource/barlow-condensed/600.css";
import "@fontsource/barlow-condensed/700.css";
import "@fontsource-variable/inter"; // Supports weights 100-900

import "/static/fonts/fonts.css";
import "@styles/cookies.css";

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
