import React, { createContext, useState, useRef } from "react";

const defaultState = {};

const AppContext = createContext(defaultState);

const AppProvider = ({ children }) => {
  const [uuid, setUuid] = useState(null);
  const headerRef = useRef(null);
  const stickyIsiRef = useRef(null);
  const footerRef = useRef(null);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const store = {
    uuid,
    setUuid,
    headerRef,
    stickyIsiRef,
    footerRef,
    showSignUpModal,
    setShowSignUpModal,
    isHeaderVisible,
    setIsHeaderVisible,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
